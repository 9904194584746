'use client';

import { posthog } from 'posthog-js';
import React, { Suspense, useEffect } from 'react';
import { PostHogProvider as PHProvider } from 'posthog-js/react';
import { usePathname, useSearchParams } from 'next/navigation';

/**
 * When we're in production and in broweser environment,
 * initialise posthog client
 */
if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
  if (!process.env.NEXT_PUBLIC_POSTHOG_HOST) {
    throw new Error('NEXT_PUBLIC_POSTHOG_HOST not provided');
  }
  if (!process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    throw new Error('NEXT_PUBLIC_POSTHOG_KEY not provided');
  }
  if (!process.env.NEXT_PUBLIC_BASE_URL) {
    throw new Error('NEXT_PUBLIC_BASE_URL not provided');
  }
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    ui_host: 'eu.posthog.com',
    // we will capture page views and exits manually
    capture_pageview: false,
    capture_pageleave: false,
    // but use auto capture for everything else
    autocapture: true,
  });
}

export function PostHogPageview({ userId }: { userId?: string | null }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // tarck page exits
  useEffect(() => {
    window.onbeforeunload = () => {
      if (pathname) {
        let url = window.origin + pathname;
        if (searchParams.toString()) {
          url = url + `?${searchParams.toString()}`;
        }
        posthog.capture(
          '$pageleave',
          {
            $current_url: url,
          },
          { transport: 'sendBeacon' },
        );
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [pathname, searchParams]);

  // Track pageviews
  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture('$pageview', {
        $current_url: url,
      });
    }
  }, [pathname, searchParams]);

  // identify user
  useEffect(() => {
    if (userId) {
      posthog.identify(userId);
    }
  }, [userId]);

  return null;
}

export function PostHogProvider({
  children,
  userId,
}: {
  children: React.ReactNode;
  userId?: string | null;
}) {
  // instrument analytics in production
  if (process.env.NODE_ENV === 'production') {
    return (
      <>
        <Suspense fallback={null}>
          <PostHogPageview userId={userId} />
        </Suspense>
        <PHProvider client={posthog}>{children}</PHProvider>
      </>
    );
  }
  // otherwise passthrough children
  return children;
}
