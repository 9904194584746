'use client';

import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import { ANALYTICS_EVENTS } from '../analytics-events';

export const useAnalyticsActions = () => {
  const posthog = usePostHog();

  const trackUserSignout = useCallback(() => {
    if (process.env.NODE_ENV !== 'production') return;
    posthog.capture(ANALYTICS_EVENTS.USER_SIGN_OUT, undefined, { transport: 'sendBeacon' });
  }, [posthog]);

  const resetAnalyticsTracking = useCallback(() => {
    if (process.env.NODE_ENV !== 'production') return;
    posthog.reset();
  }, [posthog]);

  return {
    trackUserSignout,
    resetAnalyticsTracking,
  };
};
